import React from "react";
import Layout from "../components/layout";
import parse from "html-react-parser";
import moment from "moment";
import * as showdown from "showdown";

const converter = new showdown.Converter();

export default function BlogTemplate({ location, pageContext }) {
  console.log(pageContext.blog.content);
  return (
    <Layout location={location}>
      <div className="flex flex-col gap-8 pt-16 justify-center items-center px-8 md:px-16">
        <h1 className="font-heading1" max-w-6xl>
          {" "}
          {pageContext.blog.title}
        </h1>
        <div className=" h-1/2 rounded-3xl w-full lg:w-1/2">
          {" "}
          <div className="flex-shrink-0">
            <img
              className=" w-full h-40v object-scale-down lg:h-1/2"
              src={pageContext.blog.cover_image.url}
              alt=""
            />
          </div>
        </div>
        <p className="font-heading2  ">
          {pageContext.blog.author} |{" "}
          {moment(pageContext.blog.published_date).format("MMM DD YYYY")}
        </p>

        <div className="font-body1 blog  flex-wrap  max-w-full lg:max-w-6xl">
          {parse(pageContext.blog.content)}
        </div>
      </div>
    </Layout>
  );
}
